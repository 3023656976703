import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';

import { Link } from '@powdr/components';
import {
  ThemeProps as Prop, FontFamily, Components,
  Breakpoints,
} from '@powdr/constants';
import { getFontStyles, themeUtil } from '@powdr/utils';

const CPNT = Components.PROMOTION_TRAY;

export const StyledPromotionTray = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: ${({ $minHeight }) => $minHeight}px;
`;

export const TrayHeader = styled.h2`
  padding: 40px 0;

  ${Breakpoints.mobile(css`
    padding: 20px 0;
  `)}
`;

export const PromotionList = styled.ul`
  display: flex;
  gap: 40px;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding-bottom: 50px;

  ${Breakpoints.mobile(css`
    padding-bottom: 20px;
  `)}
`;

export const PromotionListItem = styled.li`
  height: 100%;
  width: 100%;
`;

export const Promotion = styled.div`
  display: flex;
  gap: 10px;
  height: 100%;
  width: 100%;
`;

export const ImageButtonWrapper = styled.div``;

export const ContentWrapper = styled.div``;

export const Title = styled.h3`
  margin-bottom: 5px;
`;

export const Description = styled.p``;

export const PromoButton = styled(Link)`
  display: block;
  text-align: center;
  ${() => getFontStyles(FontFamily.SECONDARY_FONT)};
  line-height: normal;
  color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
  margin-top: 15px;
  padding: 8px 16px;
  background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};
  transition: color 250ms ease-out, background-color 250ms ease-in-out;

  &:hover, &:focus {
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
  }

  &:focus {
    outline: 2px solid ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
    outline-offset: 2px;
  }
`;

export const PromoImage = styled(GatsbyImage)`
  aspect-ratio: 16/9;
  height: 80px;
`;
